.addRequestPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.addRequestPopup .addPopupInner {
  bottom: 0;
  right: 0;
  position: fixed;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #d7d8e0;
  box-shadow: 0px 4px 5px -2px rgba(80, 80, 80, 0.2),
    0px 7px 10px 1px rgba(80, 80, 80, 0.14),
    0px 2px 16px 1px rgba(80, 80, 80, 0.12);
}

.addRequestPopup .addPopupInner .request-heading {
  display: flex;
  padding: 24px 29px 24px 48px;
}

.addRequestPopup .addPopupInner .request-heading .popup-heading {
  font-family: "SansSource";
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  width: 95%;
  margin-right: 29px;
}

.addRequestPopup .addPopupInner .request-heading span {
  float: right;
  cursor: pointer;
}

.addRequestPopup .addPopupInner .popupBody {
  padding: 10px 48px 4px;
}

.popupBody .select-customer {
  margin-bottom: 10px;
}

.popupBody .select-customer .select-customer-span {
  font-size: 13px;
  font-family: "SansSource";
  line-height: 16px;
  font-weight: bold;
  color: #6b6b6f;
  letter-spacing: 0.08em;
  padding-bottom: 10px;
  display: flex;
}

.requestSteps .multiple .dark-text {
  text-transform: capitalize;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
}

.requestSteps .multiple .grey-text {
  text-transform: capitalize;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: rgb(107, 107, 111);
}

.popupBody .select-span {
  font-size: 13px;
  font-family: "SansSource";
  line-height: 16px;
  font-weight: bold;
  color: #6b6b6f;
  letter-spacing: 0.08em;
  padding-bottom: 10px;
  display: flex;
}

.popupBody .select-cat:focus {
  outline-color: #1b84e7;
}

.popupBody .select-cat:invalid {
  color: #6b6b6f;
  font-size: 14px;
}

.modal-number .text-input {
  width: 100%;
  height: 40px;
  border: 1px solid #d7d7db;
  border-radius: 4px;
}

.modal-number .text-input:focus {
  outline-color: #1b84e7;
}

.quantity .number-input {
  width: 100%;
  height: 40px;
  border: 1px solid #d7d7db;
  border-radius: 4px;
}

.quantity .number-input:focus {
  outline-color: #1b84e7;
}

.last-check-price .number-input {
  width: 100%;
  height: 40px;
  border: 1px solid #d7d7db;
  border-radius: 4px;
}

.last-check-price .number-input:focus {
  outline-color: #1b84e7;
}

.date-picker {
  width: 100%;
  height: 40px;
  border: 1px solid #d7d7db;
  border-radius: 4px;
  outline-color: #1b84e7;
}

.datepicker-input {
  font-size: 13px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.footer-content .footer-cancel {
  padding: 6px 16px;
  border: 1px solid #d7d7db;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #6b6b6f;
  font-family: "SansSource";
}

.footer-content .productSaveButton button {
  padding: 6px 16px;
  height: 38px;
  border: none;
  border-radius: 4px;
  background-color: #153757;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.04em;
  font-family: "SansSource";
  align-items: center;
  text-align: center;
}

/* ------------------ User Details Pop Up Css --------------- */

.detail-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.detail-popup .popup-content {
  bottom: 0;
  right: 0;
  position: fixed;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #d7d8e0;
  box-shadow: 0px 4px 5px -2px rgba(80, 80, 80, 0.2),
    0px 7px 10px 1px rgba(80, 80, 80, 0.14),
    0px 2px 16px 1px rgba(80, 80, 80, 0.12);
}

.detail-popup .popup-heading {
  display: flex;
  padding: 24px 29px 24px 48px;
}

.detail-popup .popup-heading h2 {
  font-family: "SansSource";
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  width: 95%;
  margin-right: 29px;
}

.detail-popup .popup-heading span {
  float: right;
  cursor: pointer;
}

.detail-popup .popupbody {
  padding: 30px 48px 4px;
}

.detail-popup .popupbody .customer-detail-heading h3 {
  color: #6b6b6f;
  letter-spacing: 0.08em;
  font-family: "SansSource";
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  width: 95%;
  margin-right: 29px;
}

.detail-popup .popupbody .card-inner .name-detail {
  display: flex;
  flex-direction: column;
}

.detail-popup .popupbody .card-inner .name-detail label {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.detail-popup .popupbody .card-inner .name-detail label .name-span {
  font-size: 16px;
  font-family: "SansSource";
  font-weight: bold;
  /* width: 295px; */
}

.detail-popup .popupbody .card-inner .name-detail label .name-tag {
  float: right;
  font-size: 16px;
  font-family: "SansSource";
  color: #6b6b6f;
}

.detail-popup .popupbody .card-inner .name-detail label .contact-number-span {
  font-size: 16px;
  font-family: "SansSource";
  font-weight: bold;
}

.detail-popup .popupbody .card-inner .name-detail label .contact-tag {
  float: right;
  font-size: 16px;
  font-family: "SansSource";
  color: #6b6b6f;
}

.detail-popup .popupbody .card-inner .name-detail label .email-span {
  font-size: 16px;
  font-family: "SansSource";
  font-weight: bold;
}

.detail-popup .popupbody .card-inner .name-detail label .email-tag {
  float: right;
  font-size: 16px;
  font-family: "SansSource";
  color: #6b6b6f;
}

.detail-popup .popupbody .card-inner .product-detail .product-innerDiv {
  display: flex;
  flex-direction: column;
}

.popupbody .card-inner .product-detail .product-innerDiv label {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.popupbody .card-inner .product-detail .product-innerDiv label .category-name {
  font-size: 16px;
  font-family: "SansSource";
  font-weight: bold;
}

.popupbody
  .card-inner
  .product-detail
  .product-innerDiv
  label
  .category-product {
  float: right;
  font-size: 16px;
  font-family: "SansSource";
  color: #6b6b6f;
}

.popupbody .card-inner .product-detail label {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.popupbody .card-inner .product-detail label .quantity-name {
  font-size: 16px;
  font-family: "SansSource";
  font-weight: bold;
}

.popupbody .card-inner .product-detail label .quantity-detail {
  float: right;
  font-size: 16px;
  font-family: "SansSource";
  color: #6b6b6f;
}

@font-face {
  font-family: SansSource;
  src: url(../../../fonts/SourceSansPro-Regular.ttf);
}
