.signin-body {
  margin-top: 30px; 
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  display: -ms-flexbox;
	display: flex; 
  -ms-flex-direction: column;
  flex-direction: column;
	-ms-flex-align: center;
	align-items: center;	
  -ms-flex-pack: justify;
  justify-content: center;
  height: 80vh;
  width: 100%;
}

 
.login-wrapper{
  position: relative;
  display: -ms-flexbox;
	display: flex;
  justify-content: center;
  align-items: center; 
  border-radius: 20px;
  background: #fff;
  border-radius: 20px; 
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.22);
  -moz-box-shadow:  0px 4px 10px rgba(0, 0, 0, 0.22);
  box-shadow:  0px 4px 10px rgba(0, 0, 0, 0.22); 
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 850px;
 
}
.signin-body .login-left-banner .title{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 50;
}

.login-wrapper .img-wrap{
  position: relative;
  overflow: hidden;
  z-index: 3;
  padding-bottom: 111%;
  border-radius: 20px 0 0 20px;
}
.login-wrapper .img-wrap img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
}
.login-content.cols{
  padding: 30px 40px;
}
.login-wrapper .login-content.cols .title{
  position: static;
}
 
/* 
.login-left-banner.cols::after{ 
  content: '';
  background-image: url("../../signin-bg.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover; 
  position: absolute;
  left: 0;
  top: 0;
  z-index: 12;
} */
.login-wrapper .img-wrap::after{
  background: rgba(0,0,0,.3);
  position: relative;
  z-index: 10;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  border-radius: 20px 0 0 20px;
}
.login-wrapper .cols{
  width: 50%;
  position: relative;
  z-index: 10;
}
.login-content .title{
  margin-bottom: 20px; 
  text-align: center; 
}


.login-left-banner{
  position: relative;
}
 
.signin-body .title h1 {  
  text-align: center;
  font-family: "SansSources";
  font-weight: 600;
  font-size: 34px;
  color: #fff;  
}
.signin-body .login-center form .login-group label {
  font-family: "SansSource"; 
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px; 
}

.signin-body .login-center form .login-group .input-field {
  margin-top: 5px;
  height: 40px;
}
 

.signin-body .login-center form .login-group i {
  float: right;
  margin-right: 10px;
  margin-top: -25px;
  color: grey;
  cursor: pointer;
}

.signin-body .login-center form .forgotPassword {
  float: right;
  font-size: small;
  text-decoration: none;
  font-weight: bold;
  font-family: "SansSource"; 
  margin-top: 5px;
  text-align: right;
  width: 100%;
  margin-bottom: 30px;
}

.signin-body .login-center form .forgotPassword:hover {
  color: #143656;
}
.signin-body .form-group{
  margin-bottom: 15px;
}

.signin-body  form button {
  background-color: #143656;
  color: #fff;
  margin-top: 50px;
  min-width: 150px;
  font-family: "SansSource";  
  font-weight: 800;
  display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap; 
	-ms-flex-align: center;
	align-items: center;	
  -ms-flex-pack: justify;
  justify-content: center;
  margin-left: auto;
  margin-right: auto; 
  text-transform: uppercase;
  border: 2px solid #143656;
}
.signin-body  form button:hover,
.signin-body  form button:focus{
  background-color: #fff;
  color: #143656;
}
.signin-body .login-center form .signin-link {
  text-decoration: none;
  color: white;
} 

.container-fluid .login-footer { 
  align-items: center;
  text-align: center;
  font-family: "SansSource";
  font-size: 11px;
  color: grey;
  display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column; 
-ms-flex-wrap: wrap;
flex-wrap: wrap; 
margin-top: auto;
/* position: fixed;
left: 0;
bottom: 0; */
text-align: center;
width: 100%;
z-index: 88;
  
}

/* Forgot Password Page */

.login-page {
  width: 100%;
  height: 100%;
  /* padding: 48px 64px 44px; */
  text-align: center;
  display: flex;
  flex-direction: column;
}       

.login-page .footer-2 .copy-right-text {
  font-weight: 400 !important;
  letter-spacing: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6b6b6f;
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
}




/* forget password css */
.forget-password{
  position: relative;
  z-index: 20;
  min-height: 290px; 
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; 
  -ms-flex-pack: justify;
  justify-content: space-around;
  width: 100%;
}
.forgot-form{
  width: 100%; 
  margin: 0 auto;
  text-align: left;
}
.forgot-form label{
  margin-bottom: 6px; 
}
.forget-password .loginForm {
  text-align: left;
}
.forgot-form .login-group{
margin-bottom: 30px;
}
form.forgot-form .buttonSection{ 
-ms-flex-pack: justify;
justify-content: space-between; 
}
.signin-body form.forgot-form button{
  margin: 0;
  min-width: auto; 
}
 

@font-face {
  font-family: SansSource;
  src: url(../../fonts/SourceSansPro-Regular.ttf);
}


@media only screen and (max-width: 767px) {
  .signin-body{
    height: auto;
  }
  .login-wrapper{
    flex-wrap: wrap;
    flex-direction: column;
  }
  .login-wrapper .cols {
    width: 100%;
}
.signin-body .title h1{
  margin-bottom: 0;
}
.login-content .title{
  text-align: center;
}
 

.forgot-form{
  width: 100%;
  text-align: left;
  width: 100%;
  margin: 0 auto;
}

.login-wrapper .img-wrap { 
  padding-bottom: 60%;
  border-radius: 20px 20px 0 0px;
}
.login-wrapper .img-wrap::after{
  border-radius: 20px 20px 0 0px;
}
.login-content.cols {
  padding: 30px 20px;
}
}