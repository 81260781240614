.settingsForm{
    padding: 5px 10px;
}

.settingsForm .form-body{

}

.settingsForm .form-body .form-section{
    margin-bottom: 10px;
}

.form-section .form-heading{
    font-size: 16px;
    font-weight: bold;
    background-color: #d37f03;
    color: white;
    padding: 5px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.settingsForm .form-footer{
    margin-top: 5px;
    display: flex;
    flex-direction: row-reverse;
}

.settingsForm .form-body .form-section .input-span{
    margin: 5px 0px;
    display: inline-block;
}