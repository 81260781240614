.content-area {
  width: 83%;
  /* min-height: 300px; */
  height: calc(100vh - 75px);
  margin: 0 auto;
  display: -webkit-flex; /* Safari */
  display: flex; /* Standard syntax */
}

.content-area-chart{
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: -webkit-flex; /* Safari */
  display: flex; /* Standard syntax */
}

.emptycard {
  font-size: 16px;
  line-height: 24px;
  color: #737373;
  padding: 36px 48px 24px 48px;
  text-align: center;
  margin-top: 80px;
}

.emptycard h4 {
  color: #6b6b6f;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.emptycard ul {
  text-align: left;
  list-style-type: none;
  margin-bottom: 12px;
}
.emptycard li {
  margin: 2em 0;
}

.emptycard li.emptyState {
  margin: 24px 0;
}

.emptycard ul.emptyStateUl {
  color: #97979b;
  text-align: center;
  margin-bottom: 0px;
}
