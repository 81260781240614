.panel-wrapper .container-request .search-field{
    -ms-flex-align: center;
    align-items: center; 
    -ms-flex-pack: justify;
    justify-content: flex-end; 
    flex-direction: row;
}
.panel-wrapper .container-request .search-field .form-element-group{
     width: 30%;
}
.panel-wrapper .container-request .search-field .form-element-group input[type="text"].small{
    width: 100%;
}
.container-request .search-field .filter-drop{
    margin-right: 30px;
}
.container-request .search-field .filter-drop select{
    height: 40px;
    padding: 0 12px;
    border-radius: 4px;
}