.is-danger {
  border-color: red !important;
}

.help {
  color: red !important;
}

body {
  font-size: 14px;
  line-height: 20px;
  color: #1d3d5b;
}

img {
  max-width: 100%;
  height: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebarLess .panel-wrapper {
  width: calc(100% - 60px);
  margin-left: 60px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.panel-wrapper {
  width: calc(100% - 220px);
  margin-left: 220px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.btn.primary img {
  margin-right: 14px;
}

/* search css */
.panel-wrapper .search-field {
  border-top: 1px solid #dee2e6;
  width: 100%;
  padding: 15px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.search-field .form-element-group {
  position: relative;
  font-family: "SansSource";
}

.search-field .form-element-group input[type="text"].small {
  padding: 8px 16px 8px 12px;
  float: right;
  width: 30%;
}

.search-field .form-element-group input[type="text"] {
  border: 1px solid #d7d8e0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  font-family: inherit;
  line-height: 24px;
  outline: none;
}

.search-field .form-element-group input[type="text"]:focus {
  border-color: #1b84e7;
}

.search-field .form-element-group input[type="text"] {
  padding-left: 45px !important;
  background-color: #fff;
  background-image: url("./searchicon.svg");
  background-repeat: no-repeat;
  background-position: 8px 12px;
}

/* end search css */

.pannel-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px 50px;
  padding-right: 20px;
}

/* table css */

.card-table {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 0;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  width: 100%;
}
table.table-main {
  width: 100%;
}
.table-main tr td,
.table-main tr th {
  padding: 16px 15px;
}
.table-main thead tr th {
  background-color: #153757;
  font-weight: 700;
  letter-spacing: 1px;
  color: #737373;
  height: 48px;
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
}
.table-main tbody {
  padding: 0 40px;
}
.table-main tr:nth-child(even) {
  background-color: #f8f9fa;
}
.table-main tr:hover {
  /* background-color: #ecf6ff; */
}
.table-main .span-col {
  padding: 10px;
  width: 35px;
}

button.small {
  background: #153757 !important;
  color: #fff !important;
}

.table-main .productDetailSpan {
  display: flex;
  flex-direction: column;
}

.highlighted {
  font-weight: 600;
  border-radius: 4px;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding: 2px 8px;
  display: inline-block;
}
.decline-neutral-span {
  color: #a71a23;
  background-color: #ffe3e5;
}
.delivered-neutral-span {
  color: rgb(15, 111, 54);
  background-color: rgb(220, 241, 227);
}

.open-neutral-span {
  color: #362a96;
  background: #e6e3ff;
}
.active-neutral-span {
  color: #362a96;
  background: #e6e3ff;
}
.assignSellerButton {
  border: none;
  border-radius: 4px;
  background-color: #153757;
  padding: 4px 9px;
  transition: all 0.2s ease-out;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  color: #fff;
  letter-spacing: 0.04em;
  align-items: center;
  text-align: center;
  font-family: inherit;
}

.cancel-btn {
  padding: 6px 12px;
  border: 1px solid #d7d7db;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;
  color: #6b6b6f;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-family: "SansSource";
}

.save-btn {
  padding: 6px 12px;
  height: 38px;
  border: none;
  border-radius: 4px;
  background-color: #1b84e7;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-family: "SansSource";
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  height: 15px;
  width: 15px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
}

.option-input:hover {
  background: #9faab7;
}

.option-input:checked {
  background: #1b84e7;
}

.option-input:checked::before {
  height: 40px;
  width: 40px;
  position: absolute;
  display: inline-block;
  font-size: 26.66667px;
  text-align: center;
  line-height: 40px;
}

.option-input.radio {
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}

.CategoryStep .radio-label input {
  cursor: pointer;
  vertical-align: middle;
  margin-top: 9px;
  margin-right: 10px;
}

@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.global-popup .popupbody,
.global-popup .popupBody {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* .global-popup .addSellerPopUp .popupbody{
  overflow-y: unset;
  overflow-x: unset;
} */

.global-popup > div {
  z-index: 9999;
}

.global-popup > div::before {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}

.addRequestPopup .textfield-div {
  margin-bottom: 15px;
}

.prevScroll {
  height: 100vh;
  overflow: hidden;
}

button.disabledButton {
  background-color: #666 !important;
  color: #000;
}

.d_card_container_row {
  margin-bottom: 10px;
}

.d_card_container {
  min-height: 250px;
  padding: 5px;
  -webkit-box-shadow: 0px 0 10px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 0 10px 1px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 0 10px 1px rgba(0, 0, 0, 0.14);
}

.d_card_container .d_card .d_card_header {
  min-height: 50px;
  max-height: 80px;
  width: 100%;
  border-bottom: 1px solid #000000;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px;
  margin-bottom: 15px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  color:#1d3d5b;
}

.d_card_container .d_card_header .react-datepicker-wrapper {
  width: auto;
}

.d_card_container .d_card .d_card_body {
  width: 100%;
  margin-bottom: 10px;
}

.disabled {
  background: #ccc !important;
  cursor: not-allowed;
}

.select-date-dropbtn {
    background-color: #fafafa;
    color: black;
    padding: 5px 5px;
    font-size: 13px;
    border: none;
    cursor: pointer;
    border: 2px solid #f0d2a6;
    width: 100%;
    min-width: 200px;
    text-align: center;
}

/* Dropdown button on hover & focus */
/* .dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
} */

/* The container <div> - needed to position the dropdown content */
.select-date-dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.select-date-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.select-date-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.select-date-dropdown-content a:hover {
  background-color: #ddd;
}

.select-date-dropdown-footer{
  display: flex;
  flex-direction: row-reverse; 
  padding: 10px 10px 10px 5px;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.select-date-show {
  display: block;
}

.select-date-cancelBtn {
  padding: 6px 16px;
    border: 1px solid #d7d7db;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #6b6b6f;
    font-family: "SansSource";
    margin:0px 5px;
}

.select-date-applyBtn {
  
  padding: 6px 16px;
    border: 1px solid #d7d7db;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #153757 !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    font-family: "SansSource";
}

.select-date-left {
  right: 0;
}

.drop-table {
  opacity: 0;
  display: none;
  width: 0;
}

.drop-table.active {
  opacity: 1;
  display: table-row;
  width: 100%;
}

.drop-table.active th {
  background-color: #f4a025;
  color: #fff;
}

.drop-table.active td{
  background-color: #f7a12412;
}