.fixed-sidebar {
  height: 100vh;
  width: 220px;
  border-right: 1px solid rgb(231, 231, 236); 
  left: 0;
  z-index: 20;
  position: fixed; 
  background-color: #fff;
  -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  -webkit-box-shadow:  0px 0 10px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow:  0px 0 10px 1px rgba(0, 0, 0, 0.14);
  box-shadow:  0px 0 10px 1px rgba(0, 0, 0, 0.14);
}
.sidebarLess .fixed-sidebar {
  width: 60px;
}

.fixed-sidebar .slim-header {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.fixed-sidebar .slim-header .container-fluid {
  display: flex;
  align-self: normal;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}


 
.fixed-sidebar .slim-header .container-fluid .slim-header-left {
  align-self: normal;
  display: flex;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.fixed-sidebar .slim-header .container-fluid .slim-header-left .slim-logo { 
  margin-right: 0px;
  margin-bottom: 0px;
  letter-spacing: -2px;
  line-height: 1.2;
}
.sidebarLess .fixed-sidebar .slim-header .container-fluid .slim-header-left .slim-logo{
  padding-left: 8px;
}

.fixed-sidebar
  .slim-header
  .container-fluid
  .slim-header-left
  .slim-logo
  .logo-link {
  color: #1b84e7;
  transition: all 0.2s ease-in-out;
}

.fixed-sidebar
  .slim-header
  .container-fluid
  .slim-header-left
  .slim-logo
  .logo-link
  img {
  max-width: 111px;
  vertical-align: middle;
  border-style: none;
}

.fixed-sidebar .slim-sidebar {
  height: calc(100% - 75px); 
  padding: 35px 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;   
  position: relative;
}
.sidebarLess .fixed-sidebar .slim-sidebar {
  width: 60px;
}


.fixed-sidebar .slim-sidebar .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 1px solid #ccc;
  padding-bottom: 14px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.fixed-sidebar .slim-sidebar .nav .sidebar-nav-item {
  position: relative;
  display: block;
  width: 100%;
  list-style: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.sidebarLess .sidebar-nav-item .sidebar-nav-link span{
    opacity: 0;
    overflow: hidden;
    width: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
 } 

 .sidebarLess .slim-header-left .slim-logo .logo-link img { 
  max-height: 58px;
  width: auto;
}
.logo-link.show-sidefix{
  display: none;
}
.sidebarLess .logo-link.show-sidefix{
  display: block;
} 
.sidebarLess .slim-header-left .slim-logo .logo-link:not(.show-sidefix){
  display: none;
}
.sidebarLess .slim-header-left{
  width: auto;
  padding-left:0;
}

.sidebarLess .sidebar-nav-item .nav-active{
  padding: 0px 20px 0px 20px;
}

.sidebarLess .fixed-sidebar .slim-sidebar .nav .sidebar-nav-item .sidebar-nav-link { 
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: center; 
  -ms-flex-align: center;
  align-items: center;
}
.fixed-sidebar .slim-sidebar .nav .sidebar-nav-item .sidebar-nav-link {
  padding: 0px 20px 0px 20px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #6b6b6f;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  font-family: "SansSource";
  text-decoration: none;
  text-transform: uppercase;
}
.fixed-sidebar .slim-sidebar .nav .sidebar-nav-item .sidebar-nav-link{
  opacity: 1; 
  width: auto; 
  visibility: visible;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.fixed-sidebar .slim-sidebar .nav .sidebar-nav-item .sidebar-nav-link:hover {
  color: #d37f03;
}

.fixed-sidebar .slim-sidebar .nav .sidebar-nav-item .nav-active {
  border-top-width: 0px; 
  padding: 0px 20px 0px 20px;
  background-repeat: repeat-x;
  border-bottom-width: 0px;
  color: #d37f03; 
  text-decoration: none;
}

/* .fixed-sidebar .slim-sidebar .nav .sidebar-nav-item .nav-active:active {
  border-top-width: 0px;
  width: 200px;
  padding: 0px 25px 0px 29px;
  background-repeat: repeat-x;
  border-bottom-width: 0px;
  color: #1b84e7;
  margin-right: 1px;
  text-decoration: none;
} */

.fixed-sidebar
  .slim-sidebar
  .nav
  .sidebar-nav-item
  .sidebar-nav-link
  .siedbar-icons {
  vertical-align: middle;
  border-style: none;
}

.fixed-sidebar
  .slim-sidebar
  .nav
  .sidebar-nav-item
  .sidebar-nav-link
  .sidemeny-iconactive {
  margin-right: 18px;
  /* filter: invert(0.5) sepia(1) saturate(5) hue-rotate(110deg);
  -webkit-filter: invert(0.5) sepia(1) saturate(5) hue-rotate(110deg); */
}


.sidebar-toggle-btn{
  position: absolute;
  right: -17px;
  top: -6px;
  z-index: 88;
  border: 2px solid #143656;
  box-shadow: none;
  outline: none;
  background: #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  -webkit-box-shadow:  0px 0 10px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow:  0px 0 10px 1px rgba(0, 0, 0, 0.14);
  box-shadow:  0px 0 10px 1px rgba(0, 0, 0, 0.14);
}

.sidebarLess .sidebar-toggle-btn{
  right: -12px;
  top: -2px; 
  width: 30px;
  height: 30px; 
}
.sidebarLess .sidebar-toggle-btn svg{
  max-width: 14px;
    height: auto;
    vertical-align: middle;
    margin: -2px 0 0 0;
}

.sidebarLess .fixed-sidebar .slim-sidebar .nav .sidebar-nav-item .sidebar-nav-link .sidemeny-iconactive {
  margin-right: 0;
}


.sidebar-toggle-btn:hover,
.sidebar-toggle-btn:focus{
  outline: none;
  box-shadow: none;
}


/* all media query start here */
@media (min-width: 1200px) {
  .slim-header .container-fluid {
    padding-left: 0;
  }
}
 

@media (min-width: 1200px) {
  .slim-header.with-sidebar .slim-logo {
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .slim-sidebar {
    margin-left: 0;
    position: relative;
  }
}

@font-face {
  font-family: SansSource;
  src: url(../../fonts/SourceSansPro-Regular.ttf);
}
