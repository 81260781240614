.addSellerPopUp {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.addSellerPopUp .addPopUpInner {
  bottom: 0;
  right: 0;
  position: fixed;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #d7d8e0;
  box-shadow: 0px 4px 5px -2px rgba(80, 80, 80, 0.2),
    0px 7px 10px 1px rgba(80, 80, 80, 0.14),
    0px 2px 16px 1px rgba(80, 80, 80, 0.12);
}

.addSellerPopUp .addPopUpInner .seller-headind {
  display: flex;
  padding: 24px 29px 24px 48px;
}

.addPopUpInner .seller-headind h2 {
  font-family: "SansSource";
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  width: 95%;
  margin-right: 29px;
}

.addPopUpInner .seller-headind span {
  float: right;
  cursor: pointer;
}

.addPopUpInner .popupbody {
  padding: 36px 48px 4px;
}

.addPopUpInner .popupbody .fomik-form {
  width: 100%;
}

.popupbody .fomik-form .image-upload .image-upload-label {
  font-size: 13px;
  font-family: "SansSource";
  font-weight: 600;
  color: #6b6b6f;
  letter-spacing: 0.08em;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.popupbody .fomik-form .image-upload input[type="file"]::file-selector-button {
  padding: 0.2em 0.4em;
  background-color: #4169e1;
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 13px;
  font-family: "SansSource";
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
}

.addPopUpInner .popupbody .name-input {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.addPopUpInner .popupbody .name-input .form-control:focus {
  outline: none;
  border-color: #1b84e7;
}

.addPopUpInner .popupbody .name-input .name-span {
  font-size: 13px;
  font-family: "SansSource";
  line-height: 16px;
  font-weight: bold;
  color: #6b6b6f;
  letter-spacing: 0.08em;
  padding-bottom: 5px;
}

.addPopUpInner .popupbody .fomik-form .addProductFooter {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.addPopUpInner .popupbody .fomik-form .addProductFooter .footer-cancel {
  padding: 6px 16px;
  border: 1px solid #d7d7db;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #6b6b6f;
  font-family: "SansSource";
}

.addPopUpInner
  .popupbody
  .fomik-form
  .addProductFooter
  .productSaveButton
  button {
  padding: 6px 16px;
  height: 38px;
  border: none;
  border-radius: 4px;
  background-color: #153757;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.04em;
  font-family: "SansSource";
  align-items: center;
  text-align: center;
}

@font-face {
  font-family: SansSource;
  src: url(../../../fonts/SourceSansPro-Regular.ttf);
}
