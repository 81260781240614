.addProductPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  /* position: absolute; */
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.4); */
  background-color: transparent;
}

.addProductPopup .addPopupinner {
  /* position: absolute; */
  bottom: 0;
  right: 0;
  position: fixed;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #d7d8e0;
  box-shadow: 0px 4px 5px -2px rgba(80, 80, 80, 0.2),
    0px 7px 10px 1px rgba(80, 80, 80, 0.14),
    0px 2px 16px 1px rgba(80, 80, 80, 0.12);
}

.addProductPopup .addPopupinner .addProduct-heading {
  padding-left: 32px;
  display: flex;
  padding: 24px 29px 24px 48px;
}

.addProductPopup .addPopupinner .addProduct-heading .popup-heading {
  font-family: "SansSource";
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  width: 95%;
  margin-right: 29px;
}

.addProductPopup .addPopupinner .addProduct-heading span {
  float: right;
  cursor: pointer;
}

.addProductPopup .addPopupinner .popupInnerBody {
  padding: 36px 48px 4px;
}

.addProductPopup .addPopupinner .popupInnerBody .fomik-form {
  width: 100%;
}

.addProductPopup .addPopupinner .popupInnerBody .fomik-form .input-class:focus {
  border-color:#4169e1;
}

.addProductPopup
  .addPopupinner
  .popupInnerBody
  .fomik-form
  .image-upload
  .image-upload-label {
  font-size: 13px;
  font-family: "SansSource";
  font-weight: 600;
  color: #6b6b6f;
  letter-spacing: 0.08em;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.addProductPopup
  .addPopupinner
  .popupInnerBody
  .fomik-form
  .image-upload
  input[type="file"]::file-selector-button {
  padding: 0.2em 0.4em;
  background-color: #4169e1;
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 13px;
  font-family: "SansSource";
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
}

.addProductPopup
  .addPopupinner
  .popupInnerBody
  .fomik-form
  .activeUser-label
  span {
  display: flex;
  font-size: 13px;
  font-family: "SansSource";
  font-weight: 600;
  color: #6b6b6f;
  letter-spacing: 0.08em;
  padding: 10px 0px 5px 0px;
}

.addProductPopup .addPopupinner .popupInnerBody .fomik-form .addProductFooter {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.addProductPopup
  .addPopupinner
  .popupInnerBody
  .fomik-form
  .addProductFooter
  .footer-cancel {
  padding: 6px 16px;
  border: 1px solid #d7d7db;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #6b6b6f;
  font-family: "SansSource";
}

.addProductPopup
  .addPopupinner
  .popupInnerBody
  .fomik-form
  .addProductFooter
  .productSaveButton
  button {
  padding: 6px 16px;
  height: 38px;
  border: none;
  border-radius: 4px;
  background-color: #153757;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.04em;
  font-family: "SansSource";
  align-items: center;
  text-align: center;
}

/* TextField css */

.textfield-div label {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: "SansSource";
  line-height: 16px;
  font-weight: bold;
  color: #6b6b6f;
  letter-spacing: 0.08em;
  padding-bottom: 5px;
}

.textfield-div .form-control:focus {
  outline: none;
  border-color:#1b84e7;
}

.textfield-div .error {
  background-color: #fff7f7;
  border: 2px solid #fc5c63;
  box-shadow: 0 0 0 1px #fc5c63;
}

.textfield-div .error-msg {
  color: #a71a23;
  font-size: 13px;
  font-weight: 600;
}

@font-face {
  font-family: SansSource;
  src: url(../../../fonts/SourceSansPro-Regular.ttf);
}
